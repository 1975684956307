import { Alert, Box, Button } from '@mui/material';
import AOS from 'aos';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { ContantServices } from '../../Services/ContantServices';
import LocalizationData from '../../Translate/LocalizationData';
import img from '../../assets/Img';
import Component from '../../constants/Component';
import { LocalizationContext } from '../../context/LangChange';
import './contact.scss';
import { FetchDataContext } from '../../context/FetchData';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactUs = () => {
  let { id, image } = useParams()
  let { settings } = useContext(FetchDataContext)
  let { isLang } = useContext(LocalizationContext);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(isLang === "en" ? 'you name is required' : 'يرجى إدخال الاسم'),
    email: Yup.string().email(isLang === "ar" ? 'يرجى إدخال عنوان بريد إلكتروني صحيح' : 'Please enter a valid email address').required(isLang === "en" ? 'The email field is required.' : 'يرجى إدخال عنوان بريد إلكتروني'),
    phone: Yup.string().required(isLang === "en" ? 'The phone field is required.' : 'يرجى إدخال رقم الهاتف'),
    reason: Yup.string().required(isLang === "en" ? 'The reason field is required.' : 'يرجى إدخال السبب'),
    message: Yup.string().required(isLang === "en" ? 'The message field is required.' : 'يرجى إدخال الرسالة'),
  });

  const [meassageApi, setMeassageApi] = useState(null)
  useEffect(() => {
    window.scroll(0, 0)
    AOS.init({
      // once: true, 
    });
    AOS.refresh();
  }, [])
const [capVal, setCapVal] = useState(null)
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      reason: '',
      message: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      await ContantServices(isLang, values).then(({ data }) => {
        setMeassageApi(data?.message)
        // Reset the form inputs after successful submission
        resetForm();
      })
    }
  });


  const titleTages = () => {
    if (id == "1") {
      return isLang === "en" ? 'Digital Marketing | Right Click' : '  التسويق الإلكتروني | رايت كليك'
    } else if (id == "2") {
      return isLang === "en" ? 'Web Development | Right Click' : 'تطوير المواقع الإلكترونية | رايت كليك '
    } else if (id == "3") {
      return isLang === "en" ? 'Event Organization | Right Click' : 'تنظيم الفعاليات | رايت كليك '
    } else if (id == "4") {
      return isLang === "en" ? 'Visual Production | Right Click' : 'الإنتاج المرئي | رايت كليك'
    } else if (id == "5") {
      return isLang === "en" ? 'Visual Identity | Right Click' : 'الهوية المرئية | رايت كليك'
    } else if (id == "6") {
      return isLang === "en" ? 'Account Management | Right Click' : 'إدارة الحسابات | رايت كليك'
    } else if (id == "7") {
      return isLang === "en" ? 'Mobile Application | Right Click' : 'تطبيقات الجوال | رايت كليك'
    } else {
      return isLang === "en" ? 'Contact Us' : 'اتصل بنا'
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>  {titleTages()} </title>
        <meta name="description" content={isLang === "en" ? 'Get in touch with Right Click for top-notch digital marketing, web development, event organization, visual production, visual identity, account management, and mobile application services.' : 'تواصل مع رايت كليك للحصول على أفضل خدمات التسويق الرقمي، تطوير الويب، تنظيم الفعاليات، الإنتاج المرئي، الهوية البصرية، إدارة الحسابات، وتطبيقات الجوال.'} />
        <meta name="keywords" content={isLang === "en" ? 'Digital Marketing, Web Development, Event Organization, Visual Production, Visual Identity, Account Management, Mobile Application' : 'التسويق الرقمي، تطوير الويب، تنظيم الفعاليات، الإنتاج المرئي، الهوية البصرية، إدارة الحسابات، تطبيقات الجوال'} />
        <meta name="author" content="Right Click" />
        <meta property="og:description" content={isLang === "en" ? 'Get in touch with Right Click for top-notch digital marketing, web development, event organization, visual production, visual identity, account management, and mobile application services.' : 'تواصل مع رايت كليك للحصول على أفضل خدمات التسويق الرقمي، تطوير الويب، تنظيم الفعاليات، الإنتاج المرئي، الهوية البصرية، إدارة الحسابات، وتطبيقات الجوال.'} />
        <meta property="og:image" content={`${id ? `https://new.rightclick.com.sa/public/images/slider/${image}` : img.HeaderContactUs}`} />

        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Component.HeaderPages
        img={`${id ? `https://new.rightclick.com.sa/public/images/slider/${image}` : img.HeaderContactUs}`}
        title={LocalizationData[isLang]?.ContactUs.title}
        text={LocalizationData[isLang]?.Blogs.Text}
      />
      <div className='app__contact bg-light'>
        <Container  >
          <Row className="mt-5 " dir={isLang === "en" ? 'ltr' : 'rtl'}>

            <Col xl={7} lg={7} md={12} sm={12} className=' app__contact-content   '>
              <div className="container-content">
                {
                  meassageApi === null ?
                    <Form className="form" onSubmit={formik.handleSubmit}>

                      <Row>
                        <Col xl={6} lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label className='label-style'>{LocalizationData[isLang].ContactUs.label1}  </Form.Label>
                            <Form.Control
                              name="name"
                              value={formik.values.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isInvalid={formik.touched.name && formik.errors.name}
                              className='input-style' type="text" placeholder={LocalizationData[isLang].ContactUs.Placeholder1} />
                            {formik.touched.name && formik.errors.name && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.name}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>

                        <Col xl={6} lg={6} md={6} sm={12}>

                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                            <Form.Label className='label-style'>{LocalizationData[isLang].ContactUs.label2}  </Form.Label>
                            <Form.Control
                              name="email"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isInvalid={formik.touched.email && formik.errors.email}
                              className='input-style' type="email" placeholder={LocalizationData[isLang].ContactUs.Placeholder2} />
                            {formik.touched.email && formik.errors.email && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.email}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>

                        <Col xl={6} lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                            <Form.Label className='label-style'>{LocalizationData[isLang].ContactUs.label3}</Form.Label>
                            <Form.Control
                              name="phone"
                              value={formik.values.phone}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isInvalid={formik.touched.phone && formik.errors.phone}
                              className='input-style' type="tel" placeholder="996+" />
                            {formik.touched.phone && formik.errors.phone && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.phone}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>

                        <Col xl={6} lg={6} md={6} sm={12}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                            <Form.Label className='label-style'> {LocalizationData[isLang].ContactUs.label4}</Form.Label>
                            <Form.Control
                              name="reason"
                              value={formik.values.reason}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isInvalid={formik.touched.reason && formik.errors.reason}
                              className='input-style' type="text" placeholder={LocalizationData[isLang].ContactUs.Placeholder4} />
                            {formik.touched.reason && formik.errors.reason && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.reason}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>

                        <Col xl={12} lg={12} md={12} sm={12}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea5">
                            <Form.Label className='label-style'>{LocalizationData[isLang].ContactUs.label5}</Form.Label>
                            <Form.Control
                              name="message"
                              value={formik.values.message}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isInvalid={formik.touched.message && formik.errors.message}
                              className='input-style' as="textarea" rows={5} placeholder={LocalizationData[isLang].ContactUs.Placeholder5} />
                            {formik.touched.message && formik.errors.message && (
                              <Form.Control.Feedback type="invalid">
                                {formik.errors.message}
                              </Form.Control.Feedback>
                            )}
                          </Form.Group>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <ReCAPTCHA 
                          sitekey='6LdHHEwqAAAAABD_9KL1ivGR81NxBq1vuph7eSc_'
                          onChange={(val)=>setCapVal(val)}
                          />
                        </Col>

                        <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} display="flex" gap={'15px'} justifyContent={'space-between'} >
                          <Button
                            type='submit'
                            className='btn__overlay'
                            variant="contained"
                            color="warning"
                            sx={{
                              marginBottom: '20px',
                              textTransform: 'capitalize',
                              backgroundColor: '#FA6409',
                              color: '#24282C',
                              '&:hover': {
                                backgroundColor: '#000000',
                                color: '#ffffff',
                              },
                              fontSize: `16px !important`
                            }}
                            disabled={!capVal}
                          >
                            {LocalizationData[isLang].btnSubmit}
                          </Button>
                          <Component.SocialMedia
                            FacebookData={settings?.facebook}
                            twitterData={settings?.twitter}
                            instagramData={settings?.instagram}
                            youtubeData={settings?.youtube}
                            linkedinData={settings?.linked_in}

                            SvgColor={'#404040'} styles={'group_scoial-media-notPosotions'} />
                        </Box>

                        <hr />


                        <div className="send-message">
                          <div className="Group d-flex flex-row justify-content-between align-item-center mb-5">
                            <Component.Message />
                          </div>
                        </div>
                      </Row>
                    </Form> :

                    <>

                      <Alert variant='standard'>{meassageApi}</Alert>
                      <Box className=' btn__seeMore' flexGrow={1} marginTop={'20px'} display="flex" gap={'15px'} justifyContent={'center'} >
                        <Button
                          type="submit"
                          className='btn__overlay'
                          variant="contained"
                          color="warning"
                          sx={{
                            marginBottom: '20px',
                            textTransform: 'capitalize',
                            backgroundColor: '#FA6409',
                            color: '#24282C',
                            '&:hover': {
                              backgroundColor: '#000000',
                              color: '#ffffff',
                            },
                            fontSize: `16px !important`
                          }}
                          onClick={() => setMeassageApi(null)}
                        >
                          {isLang === "en" ? 'Do you want send anthor messange' : 'هل تريد ارسال طلب اخري'}
                        </Button>
                      </Box>
                    </>
                }
              </div>
            </Col>

            <Col xl={5} lg={5} md={false} className=' d-flex justify-content-center align-items-center' sm={false}>

              <Container className='map-contactus    ' >
                <div className="mapouter ">

                  <motion.div
                    className="gmap_canvas shadow"
                    whileInView={{ scale: [0, 1] }}
                    transition={{ duration: 1, type: 'tween' }}
                  > 
                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3623.6545363549903!2d46.64433657536694!3d24.738737778008943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ0JzE5LjUiTiA0NsKwMzgnNDguOSJF!5e0!3m2!1sen!2ssa!4v1692806342120!5m2!1sen!2ssa" width="100%"
                      height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                  </motion.div>
                </div>
              </Container>
            </Col>
          </Row>

        </Container>
      </div >
      <Component.Footer />

      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M5G766BF"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}
    </>
  )
}

export default ContactUs
